import useRemoteList from '@/views/hooks/useRemoteList'
import { ref, watch, computed } from '@vue/composition-api'
import ability from '@/libs/acl/ability'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import i18n from '@/libs/i18n'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'
import FileManager from '@/utils/FileManager'

export default function useVehiculesSearchList() {
  const selectedClientId = ref(null)

  // Filters
  const numeroSerieFilter = ref(null)

  // Use toast
  const toast = useToast()
  // use List
  const list = useRemoteList()

  const pageIndex = computed(() => list.currentPage.value - 1)

  const fetchData = () => {
    const isFrontOffice = !ability.can('manage', 'Vehicule')

    if (!isFrontOffice && ability.can('read', 'Client') && !selectedClientId.value) {
      return []
    }

    const actionName = isFrontOffice ? 'vehicule/searchVehiculesByNumeroSerie' : 'vehicule/searchVehiculesByClientAndNumeroSerie'
    let sortColumn = list.sortColumn.value ? list.sortColumn.value : 'SiteProvenanceId'
    if (sortColumn === 'SiteDestinationNom') {
      sortColumn = 'SiteDestinationId'
    }

    return store.dispatch(actionName, {
      clientId: selectedClientId.value,
      queryParams: {
        SortColumn: sortColumn,
        SortDirection: list.sortDirection.value,
        PageIndex: pageIndex.value,
        PageSize: list.perPage.value,
        NumeroSerie: numeroSerieFilter.value,
      },
    })
      .then(response => {
        const { data, headers } = response
        list.total.value = parseInt(headers['x-total-count'], 10)
        return data.vehicules
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('client.list.error.fetch'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return []
      })
  }

  const deleteItem = itemId => {
    store
      .dispatch('vehicule/deleteVehicule', itemId)
      .then(() => {
        list.refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('client.list.error.delete'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const downloadEtiquette = () => {
    store.dispatch('vehicule/fetchEtiquette').then(response => {
      if (response && response.data) { FileManager.forceFileDownload(response.data.filename, response.data.contentType, response.data.body) }
    })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Impossible d\'imprimer l\'étiquette',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch([numeroSerieFilter, selectedClientId], () => {
    if (selectedClientId.value !== null && numeroSerieFilter.value !== null && numeroSerieFilter.value.length > 2) {
      list.refetchData()
    }
  })

  // DATA
  const tableColumns = [
    { label: 'Modèle', key: 'modeleNom', sortable: true },
    {
      label: 'Provenance', key: 'siteProvenanceNom', sortable: true,
    },
    { label: 'Numéro d\'ordre', key: 'numeroOrdre', sortable: true },
    {
      label: 'Numéro de série', key: 'numeroSerie', sortable: true, stickyColumn: true,
    },
    {
      label: 'Date d\'ARA', key: 'dateARA', sortable: true, formatter: date => moment(date).format('DD/MM/YYYY'),
    },
    {
      label: 'Date de sortie', key: 'dateLivraison', sortable: true, formatter: date => moment(date).format('DD/MM/YYYY'),
    },
    {
      label: 'Destination', key: 'siteDestinationNom', sortable: true,
    },
    {
      label: 'Emplacement', key: 'emplacement', sortable: true,
    },
    {
      label: 'Stock', key: 'nbJourStock', sortable: false,
    },
  ]

  if (ability.can('update', 'Vehicule') || ability.can('delete', 'Vehicule')) {
    tableColumns.push({ label: 'Actions', key: 'actions' })
  }

  return {
    tableColumns,
    fetchData,
    deleteItem,

    // list
    ...list,

    selectedClientId,

    // filter
    numeroSerieFilter,
    downloadEtiquette,
  }
}
