<template>
  <div>
    <b-row>
      <b-col class="m-6">
        <b-card no-body>
          <b-card-body>
            <label>Numéro de série</label>
            <b-form-input
              v-model="numeroSerieFilter"
              class="w-100"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col class="m-6">
        <!-- Client Selector -->
        <client-selector
          v-if="$ability.can('read', 'Client')"
          :selected-client-id.sync="selectedClientId"
        />
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      v-if="numeroSerieFilter != null && numeroSerieFilter.length > 2"
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-cursor"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun véhicule ne correspond à la recherche"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('update', 'Vehicule')"
              :to="{ name: 'update-vehicule', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('action.edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$ability.can('delete', 'Vehicule')"
              @click="deleteItem(data.item.id )"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('action.delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }}-{{ dataMeta.to }} {{ $t('on') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import { watch } from '@vue/composition-api'
import router from '@/router'
import {
  BCard, BRow, BCol, BTable, BDropdown, BDropdownItem, BPagination, BCardBody, BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ClientSelector from '../site/sites-list/ClientSelector.vue'
import useVehiculesSearchList from './useVehiculesSearchList'
import { StorageManager, FILTER_CLIENT_KEY } from '../../utils/StorageManager'

export default {
  components: {
    ClientSelector,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    BFormInput,
    vSelect,
  },

  mounted() {
    if (router.currentRoute.params.query !== null && router.currentRoute.params.query !== undefined) {
      this.numeroSerieFilter = router.currentRoute.params.query
    }
    if (this.$ability.can('read', 'Client')) {
      if (StorageManager.getFilter(FILTER_CLIENT_KEY) != null) {
        this.selectedClientId = StorageManager.getFilter(FILTER_CLIENT_KEY)
      }
    }
  },

  setup() {
    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,
      selectedClientId,
      numeroSerieFilter,
      downloadEtiquette,
    } = useVehiculesSearchList()

    watch(selectedClientId, val => {
      StorageManager.setFilter(FILTER_CLIENT_KEY, val)
    })

    return {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,
      // client selector
      selectedClientId,
      numeroSerieFilter,
      downloadEtiquette,
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.filters {
  margin-bottom:2rem;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
